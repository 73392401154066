
$desktop: 840px;

@mixin desktop {
    @media screen and (min-width: #{$desktop}) {
        @content;
    }
}

.post {
    overflow-x:hidden;

    iframe {
        width:95%;
        text-align: center;

        @include desktop {
            max-width: 640px;
            width: 95%;
            max-height: 360px;
        }
    }
}