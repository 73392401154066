
.root {
    padding-top: 20;
    position: relative;
    height: 75vh;
    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        // justify-content: center;
        .row {
            display: flex;
            justify-content: center;
            
            .avatar {
                width: 15rem;
                height: 15rem;
                display: block;
                margin: auto;

            }
    
            .social {
                padding-right: 5px;
                flex: 1;
                margin: auto;
            }
        }
    }
}
