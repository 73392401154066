// *,
// *::after,
// *::before {
//     margin: 0;
//     padding: 0;
//     box-sizing: inherit;
// }

html {
    font-size: 100%;
}
